.howItsWorks {
    background-color: #FFFCF7;
    padding: 140px 0 60px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 70px;

        .howItsWorkBox {
            width: calc(33.33% - 30px);
            border-radius: 15px;
            padding: 10px;

            .imgBox { 
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.1);
                    margin: 0 auto 13px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                img {
                    width: 100%; 
                    padding: 20px;
                }
            }


            h2 {
                color: #282461;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: center;
                margin-bottom: 5px;
            }

            p {
                color: #1A1A19;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                /* 130% */
            }
        }
    }
}