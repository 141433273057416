.header { 
    z-index: 999;
    background-color: #FFFCF7;
    width: 100%; 
    box-sizing: border-box;
    position: sticky;
    top:0;
    .primaryBtn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 2.5vh 2px 2.5vh;
        gap: 10px;
        width: auto;
        height: 6.9vh;
        cursor: pointer;
        background: #282461;
        border-radius: 10px;
        transition: all 0.3s ease-out;
        &:hover {
          background-color: #6864a0;
        }
        p {
          font-family: "Noto Sans", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 2.4vh;
          line-height: 33px;
          letter-spacing: -0.04em;
          color: #ffffff;
        }
      }
      &* {
        box-sizing: border-box;
      }
      &.threeActive {
        background-color: #e9e8ff;
      }
      &.fiveActive {
        background-color: #e9e8ff;
      }
}

@media screen and (max-width:767px) {
  .headerContainer  {
    padding: 28px 20px !important;
  }
}
@media screen and (max-width:520px) {
  .header {
    .primaryBtn { 
      padding: 2px 10px 2px 10px;
      gap: 8px;
      width: auto;
      height:60px; 
      
      p {
        font-size: 18px !important;
      }
    }
  }
}
@media screen and (max-width:420px) {
  .header {
    .primaryBtn { 
      padding: 2px 10px 2px 10px;
      gap: 8px;
      width: auto;
      height:60px;  
      p {
        font-size: 16px !important;
      }
    }
  }
}