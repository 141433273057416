.footerBox {
    background-color: #FFFCF7;
    padding: 180px 0 55px;

    p {
        color: rgba(0, 0, 0, 0.80);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.96px;
        text-align: center;
    }
}