.provenResults {
    position: relative;
    z-index: 0;
    background: #E8E8E8 url(../../../statics/svgs/extraRowBg.svg) center center / cover;
    padding: 70px 0;
    margin-bottom: 100px;

    h2 {
        text-align: center;
    }

    .rowBox {
        position: relative;
        padding: 0 50px;

        .provenResultsOwl {
            .MuiSvgIcon-root {
                width: 50px;
                height: 50px;
                background-color: rgba(40, 36, 97, 1);
                color: #fff;
                border-radius: 50%;
                padding: 10px;
                z-index: 99;

                &.slick-prev {
                    left: -70px;
                }

                &.slick-next {
                    right: -70px;
                }
            }

            .slick-list {
                .item {
                    padding: 15px 20px;
                    img {
                        width: 100%;
                        
                        border-radius: 10px;
                        background: #FFF;
                        border: 5px solid #fff;
                        box-shadow: 0 5px 10px rgba(0,0,0,0.05);
                    }
                }
            }

        }
    }
}