.ourteamSection {
  background: url(../../statics/svgs/Looper.svg) center center / contain;
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh);
  padding-top: 12vh;
  padding-bottom: 15vh;
  position: relative;

  .grid {
    display: flex;
    justify-content: center;
  }

  .bannerTitle {
    font-style: normal;
    line-height: 121.7%;
    font-weight: 700;
    letter-spacing: -0.03em;
    color: #282461;
    width: 100%;
    font-size: 5.3vh !important;
    max-width: 75vw;
    margin: 0 auto 10vh;
    text-align: center;
  }

  .primaryBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 2px 2px 2px;
    gap: 10px;
    width: 208px;
    height: 69px;
    cursor: pointer;
    background: #282461;
    border-radius: 10px;

    p {
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.04em;
      color: #ffffff;
    }
  }

  .ourTeamsBox {
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1320px) {


  .propositionSectionGrid {
    width: calc(33% - 40px) !important;
  }

}

@media only screen and (max-width: 1200px) {
  .ourteamSection {
    .ourTeamsBox {
      flex-wrap: wrap;

      .valueGridBox {
        width: calc(50% - 15px);
        display: flex;
        flex-direction: column;

        img {
          margin: 0 auto 10px;
        }
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  .ourteamSection {
    min-height: auto;
    padding: 0 !important;

    .ourteamContainer {
      padding: 95px 20px 0 !important;

      .bannerTitle {
        font-size: 32px !important;
        max-width: 100%;
        margin: 0 auto 57px;
        text-align: left;
        line-height: normal;
      }

    }

    .grid {
      padding: 0 20px 30px !important;

      .ourTeamsBoxWarp {
        display: block;

        .ourTeamsBox {
          width: 100% !important;

          .valueGridBox {
            .subHeading {
              font-size: 40px !important;
              padding-bottom: 0;
              margin-bottom: 30px;
              line-height: normal;
            }

            .subPrea {
              font-size: 20px;
              line-height: 27px;
              min-height: auto;
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 575px) {
  .ourteamSection {
    .ourTeamsBox {
      flex-wrap: wrap;

      .valueGridBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        h3 {
          min-height: auto;
        }
        img {
          margin: 0 auto 10px;
        }
      }
    }
  }
}