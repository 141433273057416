.main {
  background:
    #E9E8FF url("../../statics/images/bg.png") center / cover;
  min-height: calc(100vh);
  padding-top: 12vh;
  padding-bottom: 15vh;
  display: flex;
  position: relative;

  .subHeading {
    font-style: normal;
    font-weight: 700;
    font-size: 5.3vh !important;
    text-align: center;
    letter-spacing: -0.04em;
    margin-bottom: 10vh;
    color: #282461;
  }
}

.OurValueHover:hover {
  background-color: #282461;
  color: #FFF;
  cursor: pointer;
  
}

.icon {
  position: relative;
  margin: 2px 8px 0px;
}

.valuePropostionBox {
  width: calc(33% - 30px);
  background: #E5E3FF;
  border: 1px solid rgba(40, 36, 97, 0.3);
  border-radius: 15px;
  padding: 3.1vh;
  text-align: center;
  transition: all 0.3s ease-out;

  h3 {
    font-style: normal;
    font-weight: 400; 
    font-size: 3.8vh;
    line-height: 121.7%;
    letter-spacing: -0.04em;
    color: rgba(0, 0, 0, 0.9);
    margin: 2.2vh 0;
    text-align: left;
  }

  .valueRow {
    display: flex;
    gap: 14px;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 6px;

    svg {
      width: 18px;
      height: auto;
      color: #282461;
      padding: 0;
      margin: 4px 0 0;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1.9vh;
      line-height: 25px; 
      letter-spacing: -0.04em; 
      color: rgba(0, 0, 0, 0.8);
    }
  }
  &:hover {
    background-color: #282461;
    h3, p, svg {
      color: #fff !important;
    }
    img {
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }
}

@media screen and (max-width:767px) {
  .main {
    padding: 0 !important;
    .ourValueContainer  {
      padding: 95px 20px 0 !important;
      .valuePropostionBoxWarp {
        display: block;
        .valuePropostionBox {
          width: 100%;
          padding: 25px;
          margin-bottom: 60px;
          img {
            width: 90px !important;
            height: 90px !important;
          }
          h3 {
            font-size: 36px !important;
            line-height: normal;
          }
          p {
            font-size: 18px !important;
          }
        }
      }
    }
    .subHeading {
      font-size: 32px !important;
      margin-bottom: 50px;
      line-height: normal;
    }
  }
  
}