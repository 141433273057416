.aIPowered { 
    padding: 65px 0 60px;
    position: relative;
    z-index: 1;

    .rowBox {
        display: flex;
        gap: 50px;
        justify-content: space-between;
        .aIPoweredBox {
            width: calc(33.33% - 25px); 
            background: #F1F0FB;
            position: relative;
            padding: 50px 50px 110px;
            border-radius: 40px;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 15px;
                left: 15px;
                right: 15px;
                bottom: 15px;
                border: 7px solid #252161;
                border-radius: 25px;
                box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
            }

            .centerBox { 
                position: relative;
                z-index: 1;
                h2 {
                    color: rgba(0, 0, 0, 0.90);
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 121.7%;
                    /* 43.812px */
                    letter-spacing: -1.44px;
                    margin-bottom: 10px;
                    text-align: left;
                }

                p {
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.76px; 
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    span {
                        width: 5px;
                        height: 5px;
                        background-color: rgba(0, 0, 0, 0.80);
                        border-radius: 50%;
                        display: inline-block;
                    }

                }

                h3 {
                    color: #282461;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.64px;

                    span {
                        font-weight: 600;
                    }
                }

            }
            .iconBox {
                width: 158px;
                height: 158px;
                border: 14px solid #252161;
                background-color: #FFFCF7;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                padding: 30px;
                align-items: center;
                margin: 0 auto;
                position: absolute;
                z-index: 1;
                bottom: -65px;
                left: 0;
                right: 0;
                box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
                img {
                    width: 100%;
                }
            }
        }
    }
}