.homeSection {
  box-sizing: border-box;
  background:
    #E9E8FF url("../../statics/images/bannerBg.png") top center / 100%;
  padding-bottom: 12px;
  margin: 0;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  .bnrImg img {
    width: 100%;
    height: 70vh;
    background: url('../../statics/images/globe.gif') no-repeat center / contain;
  }

  .scrollload {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .bannerTitle {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    color: #282461;
    font-size: 6vh !important;
  }

  .primaryBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 2px 2px 2px;
    gap: 10px;
    width: 208px;
    height: 69px;
    cursor: pointer;
    background: #282461;
    border-radius: 10px;
    transition: all 0.3s ease-out;
    &:hover {
      background-color: #6864a0;
    }

    p {
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.04em;
      color: #ffffff;
    }
  }

  & * {
    box-sizing: border-box;
  }
}

@media screen and (min-width:1600px) {
  .logoImg {
    width: 100% !important;
    max-width: 600px;
  }
}
@media screen and (max-width:1100px) {

  .bnrImg {
    padding-right: 80px;
  }

  .homeSection .bannerTitle {
    font-size: 34px !important;
  }
}

@media screen and (max-width:767px) {
  .logoImg {
    width: 100% !important;
    max-width: 400px;
  }

  .homeSection {
    height: auto;
  }
  .containerHome {
    display: block !important;
    padding:35px 20px 35px !important;
    min-height: auto !important;
  }
  .homeSection .bannerTitle {
    font-size: 32px !important;
  }
  .homeSection .primaryBtn { 
    width: 185px;
    height: 63px; 
    p {
      font-size: 20px;
    }
  }
  .bnrImg {
    padding: 80px 0 0;
    text-align: center;
  }
  .bnrImg {
    padding: 80px 0 0;
    text-align: center;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    img {
      height: auto !important;
    }
  }
}