@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');
body{
    margin: 0;
   
    // overflow: hidden; 
    /* Hide scrollbars */
}
* {
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif !important;
}