.wrapper {

  .bannerTitle {
    font-style: normal;
    line-height: 121.7%;
    font-weight: 700;
    letter-spacing: -0.03em;
    color: #282461;
    width: 100%;
    font-size: 4.4vh !important;
    max-width: 75vw;
    margin: 0 auto 10vh;
    text-align: center;
  }

  .primaryBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 2px 2px 2px;
    gap: 10px;
    width: 208px;
    height: 69px;
    cursor: pointer;
    background: #282461;
    border-radius: 10px;

    p {
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.04em;
      color: #ffffff;
    }
  }
}

@media screen and (max-width:1279px) {
  .letTalkSection {
    .clients {
      flex-wrap: wrap !important;
      gap: 25px;
      display: inline-flex;
      justify-content: center;
    } 
    .imgBox {
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
      max-width: 800px;
      .wrapper1 {
        width: calc(33.33% - 30px);
        .teamGrid .teamName { 
          font-size: 2.2vh !important; 
        }
      }
    }
    
  }

}
@media screen and (max-width:1023px) {
  .letTalkSection { 
    .imgBox {
      flex-wrap: wrap;
      margin: 0;
      max-width: 600px !important;
      .wrapper1 {
        width: calc(50% - 40px); 
        margin-bottom: 30px;
      }
    }
    
  }
}
@media screen and (max-width:767px) {
  .letTalkSection {
    padding: 0 !important;

    .letTalkContainer {
      padding: 95px 20px 50px;

      .bannerTitle {
        text-align: left;
        padding-bottom: 57px;
        font-size: 32px !important;
        line-height: normal;
      }

      .imgBox {
        flex-wrap: wrap;

        .wrapper1 {
          width: calc(50% - 10px);
          margin: 0;

          .teamGrid {
            margin-bottom: 30px;

            .teamName {
              font-size: 18px;
              line-height: 32px;
            }

            img {
              width: 100% !important;
              height: auto !important;
            }
          }
        }
      }
      .clients {
        margin-top: 30px;
      }
    }
    .overlay { 
      min-height: 150px; 
      height: auto !important;
    }

  }

}