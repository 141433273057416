.loyalty {
    background-color: #FFFCF7;
    padding: 50px 0 65px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 60px;
        align-items: center;

        .loyaltyContent {
            width: calc(49% - 60px);
            text-align: left;
            .sectionHeading {
                h2 {
                    font-weight: 600;
                    text-align: left;
                }
                p{
                    font-size: 26px;
                    font-style: italic;
                    font-weight: 300;
                }
            }
            

            p {
                color: rgba(0, 0, 0, 0.80);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.8px;
                margin:0 0 22px;
                max-width: 100%;
                text-align: left;
            } 
        }

        .loyaltyImage {
            width: 51%;

            img {
                width: 100%;
                box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.03);
            }
        }
    }
}