.whyIngeniaCep {
    background-color: #FFFCF7;
    padding-bottom: 60px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        .whyBox {
            width: calc(50% - 20px);
            border-radius: 15px;
            border: 1px solid #E8E8E8;
            background: #E8E8E8;
            padding: 25px 40px 40px;
            margin-bottom: 40px;
            .imgBox {
                width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.1);
                    margin: 0 0 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                img {
                    width: 100%; 
                    padding: 16px;
                }
            }

            h2 {
                color: #282461;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 121.7%;
                /* 43.812px */
                letter-spacing: -1.44px;
                margin: 0 0 10px;
            }

            p {
                color: rgba(0, 0, 0, 0.80);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.8px;
            }
        }
    }
}