.aboutIngeniaPdr {  
    position: relative; 
    z-index: 0; 

    h2 {
        text-align: center;
    }

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.03);
        padding: 40px 65px;
        margin-bottom: -90px;
        position: relative;
        z-index: 1;

        h2 {
            color: #28245E;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 121.7%;
            /* 48.68px */
            letter-spacing: -1.6px;
            text-transform: none;
        }

        .primaryBtn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 2px 2px 2px;
            gap: 10px;
            width: 208px;
            height: 69px;
            cursor: pointer;
            background: #282461;
            border-radius: 10px;
            transition: all 0.3s ease-out;
            margin: 0 0 0;

            &:hover {
                background-color: #6864a0;
                text-decoration: none !important;
            }

            p {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.96px;
            }
        }
    }
}