.enablingMarketers {
    background-color: #FFFCF7;
    padding: 60px 0 60px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 50px;

        .enablingMarketersBox {
            width: calc(33.33% - 25px);
            border-radius: 15px;
            background: #E8E8E8;
            padding: 50px 30px;

            .iconBox {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: rgba(40, 36, 97, 1);
                border: 10px solid #fff;
                margin: 0 auto 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                text-align: center;
                font-size: 50px;
                font-style: normal;
                font-weight: 500;
                line-height: 121.7%;
                /* 60.85px */
                letter-spacing: -2px;
            }


            h2 {
                color: #282461;
                text-align: center;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 30px;
            }

            p {
                color: rgba(0, 0, 0, 0.80);
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.8px;
                margin: 0;
            }
        }
    }
}