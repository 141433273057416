.blogPage {
    box-sizing: border-box;

    & * {
        box-sizing: border-box;
    }

    .header {
        background-color: #e9e8ff;
    }

    .blog {
        max-width: 1420px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 40px; 
        .blogRow {
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
            .primaryBtn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 5px 15px;
                gap: 10px;
                width: auto;
                height: 40px;
                cursor: pointer;
                background: #282461;
                border-radius: 10px;
                transition: all 0.3s ease-out;
                font-family: "Noto Sans", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 33px;
                letter-spacing: -0.04em;
                color: #ffffff;
                text-decoration: none;

                &:hover {
                    background-color: #6864a0;
                }


            }
        }
    }

}