
.animationBtn {
    position: fixed;
    z-index: 999;
    bottom: 5vh;
    left: 0;
    right: 0;
    margin: auto;
    width: 35px;
  }

  @media screen and (max-width:767px) {
    .animationBtn {
      display: none !important;
    }
  }