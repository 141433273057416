.rewardPoints {
    padding-bottom: 50px;
    background: #282461;
    margin-top: 320px;

    h2 {
        text-align: center;
    }

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: flex-start;
        border-bottom: 1px solid #fff;
        padding: 0 0 60px;

        .rewardPointsBox {
            width: calc(33.33% - 12px);
            text-align: center;
            margin-top: -270px;

            h2 {
                color: #FFF;
                text-align: center;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -1.2px;
                text-transform: capitalize;
            }
        }

        img {
            width: 100%;
            height: 580px;
            object-fit: contain;
            margin-bottom: 10px;
        }
    }

    .listRow {
        display: flex;
        padding: 50px 0;

        .listBox {
            width: 50%;
            padding: 25px 20px;

            h3 {
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -1.6px;
                margin-bottom: 16px;
                color: #FFF;
            }

            ul {
                margin: 0;
                padding-left: 18px;
                padding-right: 150px;

                li {
                    color: rgba(255, 255, 255, 0.80);
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.96px;
                    margin: 0;
                }

            }

            &:first-child {
                border-right: 1px solid #fff;
            }

            &:last-child {
                h3 {
                    padding-left: 125px;
                }
                ul {
                    padding-left: 150px;
                    padding-right: 0;
                }
            }
        }
    }
}