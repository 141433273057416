.cepPage {
    box-sizing: border-box;
    background-color: #FFFCF7;

    * {
        box-sizing: border-box;
    }

    .containerBox {
        max-width: 1420px;
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;

    }

    .containerFluidBox {
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;
    }
}

.letsTalkPopup {
    max-width: 700px;
    margin: 0 auto;

    .contactUs {
        padding: 30px 0 30px;
        min-height: auto;
        border-radius: 10px;
        max-height: 90vh;
        overflow-y: auto;
        scrollbar-width: thin;

        .textAreaGroup .textAreaField textarea {
            height: 70px !important;
        }

        .inputGroup .inputField input,
        .inputGroup .inputField select {
            padding: 0 20px;

        }
    }
}

@media only screen and (max-width: 1279px) {
    .letsTalkPopup {
        max-width: 700px;
        margin: 0 auto;

        .contactUsContainer {
            padding: 0 20px !important;

            .pageTitle {
                margin-bottom: 30px;

                h2 {
                    font-size: 30px;
                }

                p {
                    font-size: 20px;
                }
            }

            .inputGroup .inputField .MuiInputBase-root input {
                height: 50px !important;
                padding: 0 15px !important;
            }

            .formArea .inputFieldBox .inputGroup .inputLabel {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: normal;
                color: #000000;
                margin: 0px 0 10px;
            }

            .formArea .inputFieldBox {
                width: calc(100% - 0px);
                margin-bottom: 25px;
            }

            .primaryBtn {
                margin-top: 35px;

                button {
                    width: 200px;
                    height: 55px;
                    font-size: 17px;
                }
            }
        }
    }

    .cepPage {
        .header {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

            img {
                width: 100% !important;
                max-width: 140px;
            }

            .primaryBtn {
                height: 50px;

                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.64px;
                }

                img {
                    width: 18px !important;
                }
            }
        }

        .containerBox {
            padding: 0 25px;
        }

        .containerFluidBox {
            padding: 0 25px;
        }

        .cepSection {
            padding-bottom: 0;

            .cepBanner {
                display: block;

                .bannerContent {
                    width: 100%;

                    h2 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 49px;
                        /* 153.125% */
                        letter-spacing: -0.96px;

                        span {
                            line-height: 49px;
                        }
                    }

                    p {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;
                        /* 175% */
                        letter-spacing: -0.64px;
                    }

                    a {
                        height: 50px;
                        margin-top: 35px;
                        width: 160px;

                        p {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }
                }

                .inBannerImg {
                    width: 100%;
                }
            }
        }

        .whyIngeniaCep {
            padding-bottom: 50px;

            .rowBox {
                display: block;

                .whyBox {
                    width: 100%;
                    margin-bottom: 16px;

                    h2 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 121.7%;
                        /* 24.34px */
                        letter-spacing: -0.8px;
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    .imgBox {
                        width: 60px;
                        height: 60px;

                        img {
                            padding: 12px;
                        }
                    }
                }
            }
        }

        .engage {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                .engageContent {
                    width: 100%;

                    .sectionHeading {
                        h2 {
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            letter-spacing: -1.28px;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    .listBox {
                        margin-bottom: 25px;

                        li {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }
                }

                .engageImage {
                    width: 100%;
                }
            }
        }

        .keyFeatures {
            padding: 0 0 50px;

            .rowBox {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 5px;

                .keyFeaturesBox {
                    width: calc(50% - 5px);
                    margin-bottom: 15px;
                    text-align: center;

                    img {
                        width: 100%;
                        display: inline-block;
                    }

                    h2 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

            }
        }


        .enablingMarketers {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                .enablingMarketersBox {
                    width: 100% !important;
                    margin-bottom: 15px;

                    &:last-child {
                        margin: 0;
                    }

                    h2 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-bottom: 16px;
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    .iconBox {
                        width: 100px;
                        height: 100px;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 121.7%;
                        border: 6px solid #fff;
                        /* 40.567px */
                        letter-spacing: -1.333px;
                        margin-bottom: 16px;
                    }
                }
            }
        }

        .loyalty {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                .loyaltyContent {
                    width: 100%;

                    .sectionHeading {
                        margin: 0;

                        p {
                            font-size: 20px;
                            font-style: italic;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.8px;
                            margin: 0;
                        }

                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;
                        /* 175% */
                        letter-spacing: -0.64px;
                    }
                }

                .loyaltyImage {
                    width: 100%;
                }
            }
        }

        .rewardPoints {
            padding: 50px 0 30px;
            margin-top: 150px;

            .rowBox {
                padding: 0 0 30px;

                .rewardPointsBox {
                    margin-top: -150px;

                    img {
                        height: auto;
                    }

                    h2 {
                        font-size: 10.492px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.42px;
                        text-transform: capitalize;
                    }
                }
            }

            .listRow {
                padding: 0 0;
                display: block;

                .listBox {
                    width: 100%;

                    &:first-child {
                        border: none;
                        border-bottom: 1px solid #fff;
                    }

                    h3 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: -1.28px;
                        padding-left: 0 !important;
                    }

                    ul {
                        padding: 0 0 0 17px !important;

                        li {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }
                }
            }
        }

        .whatWeHaveDone {
            padding: 50px 0 50px;

            h2 {
                text-align: center;
                margin-bottom: 15px;
            }

            .rowBox {
                display: block;

                .whatWeHaveDoneBox {
                    width: 100%;
                    margin-bottom: 32px;

                    &:last-child {
                        margin: 0;
                    }

                    h2 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: -1.28px;
                        text-transform: capitalize;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                        text-transform: capitalize;
                    }
                }

            }
        }

        .purchaseExperience {
            padding: 20px 0 50px;

            .rowBox {
                display: block;

                .purchaseExperienceOwl {
                    .MuiSvgIcon-root {
                        width: 32px;
                        height: 32px;
                        background-color: rgba(40, 36, 97, 1);
                        color: #fff;
                        border-radius: 50%;
                        padding: 5px;
                        z-index: 99;

                        &.slick-prev {
                            left: -40px;
                        }

                        &.slick-next {
                            right: -40px;
                        }
                    }
                }
            }
        }

        .provenResults {
            padding: 50px 0 50px;

            .rowBox {
                display: block;

                .provenResultsOwl {
                    .MuiSvgIcon-root {
                        width: 32px;
                        height: 32px;
                        background-color: rgba(40, 36, 97, 1);
                        color: #fff;
                        border-radius: 50%;
                        padding: 5px;
                        z-index: 99;

                        &.slick-prev {
                            left: -40px;
                        }

                        &.slick-next {
                            right: -40px;
                        }
                    }
                }
            }
        }

        .aboutIngeniaCep {
            padding: 0 0 0;

            .rowBox {
                display: block;
                text-align: center;
                padding: 40px 15px;
                margin: 0;

                h2 {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 121.7%;
                    /* 38.944px */
                    letter-spacing: -1.28px;
                    max-width: 550px;
                    margin: 0 auto;
                }

                a {
                    margin: 35px auto 0;
                    width: 140px;
                    height: 50px;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }
                }
            }
        }

        .footerBox {
            background-color: #FFFCF7;
            padding: 50px 0 55px;

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.64px;
            }
        }
    }
}


@media screen and (max-width: 1279px) and (min-width: 768px) {
    .cepPage {
        .cepSection {
            padding-bottom: 0;

            .cepBanner {
                display: flex;

                .bannerContent {
                    width: calc(50% - 15px);

                }

                .inBannerImg {
                    width: 50%;
                }
            }
        }

        .whyIngeniaCep {
            padding: 50px 0px 35px;

            .rowBox {
                display: flex;
                margin-bottom: 15px;

            }
        }

        .engage {
            padding: 35px 0;

            .rowBox {
                display: flex;
                gap: 30px;
                align-items: center;

                .engageContent {
                    width: calc(50% - 30px);
                }

                .engageImage {
                    width: 50%;
                }
            }
        }

        .keyFeatures {
            padding: 35px 0;

            .rowBox {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 5px;

                .keyFeaturesBox {
                    width: calc(25% - 5px);
                    margin-bottom: 15px;
                    text-align: center;

                    img {
                        width: 100%;
                        display: inline-block;
                    }

                }

            }
        }


        .enablingMarketers {
            padding: 35px 0;

            .sectionHeading h2 {
                text-align: center;
            }

            .rowBox {
                display: flex;
                gap: 15px;

                .enablingMarketersBox {
                    width: calc(33.33% - 15px) !important;
                    margin-bottom: 0;
                    padding: 30px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        .loyalty {
            padding: 35px 0;

            .rowBox {
                display: flex;
                gap: 30px;

                .loyaltyContent {
                    width: calc(49% - 30px); 
                }

                .loyaltyImage {
                    width: 50%;
                }
            }
        }

        .rewardPoints {
            padding: 20px 0 35px;
            margin-top: 150px; 

            .listRow {
                padding: 30px 0;
                display: flex;

                .listBox {
                    width: 100%;
                    border: none;
                    padding-left: 8vw;
                    &:first-child {
                        border-right: 1px solid #fff;
                        border-bottom: none;
                        padding-left: 0;
                    }  
                }
            }
        }

        .whatWeHaveDone {
            padding: 70px 0 35px;

            h2 {
                text-align: center;
                margin-bottom: 15px;
            }

            .rowBox {
                display: flex;
                gap: 15px;
                .whatWeHaveDoneBox {
                    width: calc(33.33% - 15px);
                    margin-bottom: 0;

                    &:last-child {
                        margin: 0;
                    } 
                     
                }

            }
        }

        .purchaseExperience {
            padding: 35px 0 35px;

            .rowBox {
                display: block; 
                 
            }
        }

        .provenResults {
            padding:35px 0 35px;

            .rowBox {
                display: block; 
                 
            }
        }

        .aboutIngeniaCep {
            padding: 0 0 0;

            .rowBox {
                display: flex;
                text-align: left;
                padding: 30px;
                margin: 0;

                .sectionHeading {
                    margin: 0 !important;
                }

                h2 {
                    margin: 0 0;
                    text-align: left;
                }

                a {
                    margin: 0 auto 0;
                }
            }
        }

        .footerBox {
            background-color: #FFFCF7;
            padding: 50px 0 55px;
        }
    }
}