.letTalkSection { 
  background-color: #E9E8FF;
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh);
  padding-top: 13vh;
  padding-bottom: 15vh;
  position: relative;

  .bannerTitle {
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    color: #282461;
    text-align: center;
    padding-bottom: 5vh;
    padding-top: 0;
    line-height: 121.7%;
    font-size: 4.4vh !important;
  }

  .primaryBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 2px 2px 2px;
    gap: 10px;
    width: 208px;
    height: 69px;
    cursor: pointer;
    background: #282461;
    border-radius: 10px;

    p {
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.04em;
      color: #ffffff;
    }
  }

  .teamGrid {
    margin: 0;
    border: 1px solid #282461;
    position: relative;
    border-radius: 20px;
    padding-top: 0;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;

    .teamName {
      width: 100%;
      background: #282461;
      opacity: 0.8;
      border-radius: 2vh;
      text-align: center;
      color: #FFF;
      height: 7vh;
      line-height: 7vh;
      position: absolute;
      text-transform: uppercase;
      bottom: 0;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 2.4vh;
      justify-content: center;
      display: flex;
      align-content: center;
      align-items: center;
      color: #FFFFFF;
    }

    img { 
      width: 100%;
      border-radius: 20px;
      top: 4px;
      position: relative; 
      height: auto;
      object-fit: contain;
      object-position: top; 
      
    }
  }
}

.wrapper1 {  
  width: calc(25% - 30px); 
}


.container {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  padding-bottom: 18px;
  padding-top: 18px;
  background: rgba(40, 36, 97, 0.8);
  opacity: 0.8;
  // border-top-left-radius:20px;
  // border-top-right-radius:20px;
  border-radius: 20px;
  text-align: center;
  color: #FFF; 
  position: absolute;

  cursor: pointer;




  color: #f1f1f1;
  width: 100%;
  min-height: 150px;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  // padding: 20px;
  text-align: center;

  span {
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 3px;
    display: inline-block;
  }
}

.container {
  cursor: pointer;
}

.container:hover .overlay {
  opacity: 1;
  cursor: pointer;
}
.container:hover  .teamName { 
  opacity: 0;
}
@media only screen and (max-width: 1600px) {
  .teamGrid  {
    text-align: center;
  }  
  .wrapper1 {  
    width: calc(20% - 30px); 
  }
}
@media only screen and (max-width: 575px) {
  .container:hover .overlay {
    opacity: 0;
    cursor: pointer;
    display: none;
  }
  .container:hover  .teamName { 
    opacity: 1;
  }
}
@media only screen and (max-width: 479px) {
  .letTalkSection .letTalkContainer .imgBox .wrapper1 .teamGrid .teamName {
    font-size: 13px !important;
    line-height: normal;
    height: 6vh;
  }
}