.keyFeatures {
    position: relative;
    z-index: 0;
    padding-bottom: 50px;

    h2 {
        text-align: center;
    }

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: flex-start;
        .keyFeaturesBox {
            h2 {
                color: #282461;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: center;
            }
        } 
        img {
            width:100%
        }
    }
}