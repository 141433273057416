.contactUs {
  width: 100%;
  margin: 0 auto;
  background-color: #fffcf7;
  min-height: calc(100vh);
  padding-top: 12vh;
  padding-bottom: 7vh;
  position: relative;

  .pageTitle {
    width: 100%;
    margin-bottom: 3vh;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 4.4vh;
      text-align: center;
      letter-spacing: -0.04em;
      color: #282461;
      margin: 0 0 10px;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 3vh;
      letter-spacing: -0.04em;
      color: rgba(0, 0, 0, 0.8);
      margin: 0;
    }
  }

  .formArea {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .inputFieldBox {
      width: calc(50% - 19px);
      margin-bottom: 2.4vh;

      .dropDownLabel {
        font-style: normal;
        font-weight: 600;
        font-size: 2.2vh;
        line-height: normal;
        color: #000000;
        margin: 0 0 1.8vh;

        span {
          color: #f10000;
        }
      }

      .customDropDown {
        width: 100%;

        .MuiInputBase-root {
          font-weight: 400;
          font-size: 16px;
          height: 7vh;
          color: #000;
          padding: 0 0 0 36px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 10px;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }

          .MuiSelect-select {
            background: transparent;
          }
        }
      }

      // .inputWithPhoneCode {
      //     position: relative;

      //     .customDropDown {
      //         width: 100px;
      //         position: absolute;
      //         left: 0;
      //         top: 0;

      //         .MuiInputBase-root {
      //             font-weight: 400;
      //             font-size: 16px;
      //             height: 70px;
      //             color: #000;
      //             padding: 0 0 0 36px;
      //             border: none;
      //             border-radius: 10px;

      //             &::before {
      //                 display: none;
      //             }

      //             &::after {
      //                 display: none;
      //             }

      //             .MuiSelect-select {
      //                 background: transparent;
      //             }
      //         }
      //     }
      // }
    }

    .textAreaFieldBox {
      width: 100%;
    }
  }

  .primaryBtn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 4vh;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 13px;
      width: 284px;
      height: 69px;
      cursor: pointer;
      // background: #282461;
      border-radius: 10px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.04em;
      color: #ffffff;
      text-transform: capitalize;
      &:hover {
        background-color: #6864a0;
      }
    }
  }
}

.customPopUp {
  max-width: 725px;
  width: 100%;
  padding: 24px 50px 90px;
  text-align: center;
  border-radius: 10px;
  border: none !important;
  outline: none;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #282461;
    margin: 0 0 15px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    text-align: center;
    letter-spacing: -0.04em;
    color: rgba(0, 0, 0, 0.8);
  }
}

@media only screen and (max-width: 767px) {
  .contactUs {
    padding: 0;
    min-height: auto;

    .contactUsContainer {
      padding: 50px 20px !important;

      .pageTitle {
        margin-bottom: 50px;

        h2 {
          font-size: 32px;
          text-align: left;
        }

        p {
          font-size: 30px;
          text-align: left;
        }
      }
      .formArea {
        width: 100%;
        display: block; 
        .inputFieldBox {
          width: calc(100% - 0px);
          margin-bottom: 40px;
          .inputGroup .inputLabel , .dropDownLabel {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: normal;
            color: #000000;
            margin: 0 0 18px;
          }

           
        }
        .textAreaGroup .textAreaLabel {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: normal;
          color: #000000;
          margin: 0 0 18px;
        }
      }
    }
    .primaryBtn{
      margin-top: 63px;
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 13px;
        width: 250px;
        height: 63px;
        cursor: pointer;
        border-radius: 10px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px; 
        text-transform: capitalize;
      }
    } 
  }
  .inputGroup .inputField .MuiInputBase-root input { 
    height: 63px !important; 
    padding: 0 20px !important
  }
  .contactUs .formArea .inputFieldBox .customDropDown .MuiInputBase-root { 
    height:  63px !important;
    padding: 0 20px !important;
  }
  .textAreaGroup .textAreaField .MuiInputBase-root textarea { 
    height: 350px !important; 
    padding: 20px; 
  }
  .customPopUp {
    max-width: 725px;
    width: 100%;
    padding: 0px 20px 20px;
    text-align: center;
    border-radius: 10px;
    border: none !important;
    outline: none;
  
    h2 { 
      font-size: 30px; 
    }
  
    p { 
      font-size: 24px; 
    }
  }
  
}