.extraRow {
    background: #E8E8E8 url(../../../statics/svgs/extraRowBg.svg) center center / cover;
    padding: 70px 0;
    .rowBox {
        display: flex;
        gap: 25px;
        justify-content: space-between;
        .exBox {
            img {
                width: 100%;
            }
        }
    }
}