.cdpPage {
    box-sizing: border-box;
    background-color: #FFFCF7;

    * {
        box-sizing: border-box;
    }

    .containerBox {
        max-width: 1420px;
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;

    }

    .containerFluidBox {
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;
    }
}

.letsTalkPopup {
    max-width: 700px;
    margin: 0 auto;

    .contactUs {
        padding: 30px 0 30px;
        min-height: auto;
        border-radius: 10px;
        max-height: 90vh;
        overflow-y: auto;
        scrollbar-width: thin;

        .textAreaGroup .textAreaField textarea {
            height: 70px !important;
        }

        .inputGroup .inputField input,
        .inputGroup .inputField select {
            padding: 0 20px;

        }
    }
}




@media only screen and (max-width: 1279px) {
    .letsTalkPopup {
        max-width: 700px;
        margin: 0 auto;

        .contactUsContainer {
            padding: 0px 20px !important;

            .pageTitle {
                margin-bottom: 30px;

                h2 {
                    font-size: 30px;
                }

                p {
                    font-size: 20px;
                }
            }

            .inputGroup .inputField .MuiInputBase-root input {
                height: 50px !important;
                padding: 0 15px !important;
            }

            .formArea .inputFieldBox .inputGroup .inputLabel {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: normal;
                color: #000000;
                margin: 0px 0 10px;
            }

            .formArea .inputFieldBox {
                width: calc(100% - 0px);
                margin-bottom: 25px;
            }

            .primaryBtn {
                margin-top: 35px;

                button {
                    width: 200px;
                    height: 55px;
                    font-size: 17px;
                }
            }
        }
    }

    .cdpPage {
        .header {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

            img {
                width: 100% !important;
                max-width: 140px;
            }

            .primaryBtn {
                height: 50px;

                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.64px;
                }

                img {
                    width: 18px !important;
                }
            }
        }

        .containerBox {
            padding: 0 25px;
        }

        .containerFluidBox {
            padding: 0 25px;
        }

        .cdpSection {
            padding-bottom: 0;

            .cdpBanner {
                display: block;

                .bannerContent {
                    width: 100%;

                    h2 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 49px;
                        /* 153.125% */
                        letter-spacing: -0.96px;

                        span {
                            line-height: 49px;
                        }
                    }

                    p {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;
                        /* 175% */
                        letter-spacing: -0.64px;
                    }

                    a {
                        height: 50px;
                        margin-top: 35px;
                        width: 160px;

                        p {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }
                }

                .inBannerImg {
                    width: 100%;
                }
            }
        }

        .whyIngenia {
            padding-bottom: 50px;

            .rowBox {
                display: block;

                .whyBox {
                    width: 100%;
                    margin-bottom: 16px;

                    h2 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 121.7%;
                        /* 24.34px */
                        letter-spacing: -0.8px;
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    .imgBox {
                        width: 60px;
                        height: 60px;

                        img {
                            padding: 12px;
                        }
                    }
                }
            }
        }

        .extraRow {
            .rowBox {
                display: block;

                .exBox {
                    margin-bottom: 20px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        .howItsWorks {
            padding: 50px 0;

            .rowBox {
                display: block;

                .howItsWorkBox {
                    width: 100%;
                    margin-bottom: 15px;

                    &:last-child {
                        margin: 0;
                    }

                    h2 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        /* 162.5% */
                    }
                }
            }
        }

        .microSegmentation {
            padding: 50px 0;

            .rowBox {
                display: block;

                .microSegmentationContent {
                    width: 100%;
                    margin-bottom: 50px;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    h3 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 121.7%;
                        /* 24.34px */
                        letter-spacing: -0.8px;
                    }

                    .tagBox {
                        span {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 121.7%;
                            /* 19.472px */
                            letter-spacing: -0.64px;
                        }
                    }

                }

                .microSegmentationImage {
                    width: 100%;
                }
            }
        }

        .campaignRecommendation {
            padding: 0 0 50px;

            .rowBox {
                display: grid;
                gap: 0;

                .campaignRecommendationContent {
                    width: 100%;
                    order: 1;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }
                }

                .campaignRecommendationImage {
                    width: 100%;
                    order: 2;
                }
            }
        }

        .profileBuilding {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                .profileBuildingContent {
                    width: 100%;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }
                }

                .profileBuildingImage {
                    width: 100%;
                }
            }
        }

        .aIAlgorithms {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                .aIAlgorithmsImage {
                    width: 100%;
                }
            }
        }

        .statisticsData {
            padding: 0 0 50px;

            .rowBox {
                display: block;
                background: url(../../statics/svgs/StatisticsDataBgM.svg) no-repeat center center / contain;
                position: relative;

                .statisticsDataBox {
                    width: 100%;
                    min-height: 350px;

                    .centerBox {
                        max-width: 260px;

                        h2 {
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 121.7%;
                            /* 48.68px */
                            letter-spacing: -1.6px;
                        }

                        p {
                            font-size: 15.096px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.604px;
                        }
                    }
                }
            }
        }

        .aIPowered {
            padding: 20px 0 50px;

            h2 {
                text-align: left !important;
            }

            .rowBox {
                display: block;

                .aIPoweredBox {
                    width: 100%;
                    margin-bottom: 100px;
                    padding: 40px 40px 110px;

                    &:last-child {
                        margin: 0;
                    }

                    .centerBox {
                        h2 {
                            font-size: 31.594px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 121.7%;
                            /* 38.449px */
                            letter-spacing: -1.264px;
                        }

                        p {
                            font-size: 16.674px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.667px;
                        }
                    }
                }
            }
        }





        .aboutIngenia {
            padding: 350px 0 0;

            .rowBox {
                display: block;
                text-align: center;
                padding: 40px 15px;
                margin: 0;

                h2 {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 121.7%;
                    /* 38.944px */
                    letter-spacing: -1.28px;
                    max-width: 550px;
                    margin: 0 auto;
                }

                a {
                    margin: 35px auto 0;
                    width: 140px;
                    height: 50px;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }
                }
            }
        }

        .footerBox {
            background-color: #FFFCF7;
            padding: 210px 0 55px;

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.64px;
            }
        }
    }
}

@media screen and (max-width: 1279px) and (min-width: 768px) {
    .cdpPage {
        .cdpSection {
            padding-bottom: 0;

            .cdpBanner {
                display: flex;

                .bannerContent {
                    width: calc(50% - 15px);

                }

                .inBannerImg {
                    width: 50%;
                }
            }
        }

        .whyIngenia {
            padding: 50px 0px 50px;

            .rowBox {
                display: flex;
                margin-bottom: 15px;

            }
        }

        .extraRow {
            padding: 50px 0 30px;

            .rowBox {
                display: flex;

                .exBox {
                    margin-bottom: 20px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        .howItsWorks {
            padding: 70px 0 35px;

            .rowBox {
                display: flex;
                gap: 15px;

                .howItsWorkBox {
                    width: calc(33.33% - 15px);
                    margin-bottom: 0;
                }
            }
        }

        .microSegmentation {
            padding: 35pxx 0;

            .rowBox {
                display: flex;
                gap: 30px;

                .microSegmentationContent {
                    width: calc(50% - 30px);
                    margin-bottom: 0;



                    .tagBox {
                        span {
                            padding: 10px 25px;
                        }
                    }

                }

                .microSegmentationImage {
                    width: 50%;
                }
            }
        }

        .campaignRecommendation {
            padding: 35px 0;

            .rowBox {
                display: flex;
                gap: 30px;

                .campaignRecommendationContent {
                    width: calc(50% - 30px);
                    order: unset;
                }

                .campaignRecommendationImage {
                    width: 50%;
                    order: unset;
                }
            }
        }

        .profileBuilding {
            padding: 35px 0;

            .rowBox {
                display: flex;
                gap: 30px;

                .profileBuildingContent {
                    width: calc(50% - 30px);
                }

                .profileBuildingImage {
                    width: 50%;
                }
            }
        }

        .aIAlgorithms {
            padding: 35px 0;

            .rowBox {
                display: flex;
                gap: 6px;

                .aIAlgorithmsImage {
                    width: calc(50% - 6px);
                }
            }
        }

        .statisticsData {
            padding: 0 0 30px;

            .rowBox {
                display: flex;
                background: url(../../statics/svgs/StatisticsDataBg.svg) no-repeat center center / contain;
                position: relative;

                .statisticsDataBox {
                    width: calc(33.3333% - 0px);
                    min-height: 300px;

                    .centerBox {
                        max-width: 200px;

                        p {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }  

        .aboutIngenia {
            padding: 410px 0 0;

            .rowBox {
                display: flex;
                text-align: left;
                padding: 30px;
                margin: 0;
                align-items: center;
                .sectionHeading {
                    margin: 0 !important;
                }
                h2 { 
                    max-width: 550px;
                    margin: 0 ; 
                    text-align: left;
                }

                a {
                    margin: 0 auto 0;
                    width: 140px;
                    height: 50px;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }
                }
            }
        }

        .footerBox {
            background-color: #FFFCF7;
            padding: 180px 0 55px; 
        }
    }
}


//-------------------- aIPowered
@media screen and (max-width: 1279px) and (min-width: 900px) {
    .cdpPage {
        .aIPowered {
            padding: 20px 0 50px;

            h2 {
                text-align: left !important;
            }

            .rowBox {
                display: flex;
                gap: 10px;
                .aIPoweredBox {
                    width: calc(33.33% - 10px);
                    margin-bottom: 100px ;
                    padding: 40px 40px 110px;

                    &:last-child {
                        margin-bottom: 100px;
                    }

                    .centerBox {
                        h2 {
                            font-size: 26px;
                            text-align: left !important;
                        } 
                    }
                }
            }
        }
        .aboutIngenia {
            padding: 270px 0 0;
            height: 335px;
          }
    }
}
//-------------------- aIPowered