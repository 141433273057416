.engage {
    background-color: #FFFCF7;
    padding: 65px 0 65px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 80px; 

        .engageContent {
            width: calc(50% - 80px);
            text-align: left;

            h2 {
                font-weight: 600;
                text-align: left;
                font-size: 40px;
            }

            p {
                color: rgba(0, 0, 0, 0.80);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.8px;
                margin:0 0 22px;
                max-width: 100%;
                text-align: left;
            }



            .listBox {
                margin: 0; 
                padding-left: 18px;  

                li {
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.8px;
                    margin: 0;
                    strong {
                        font-weight: 600;
                    }
                }
            }
        }

        .engageImage {
            width: 50%;

            img {
                width: 100%;
            }
        }
    }
}