.blogDetailsPage {
    box-sizing: border-box;

    & * {
        box-sizing: border-box;
    }

    .header {
        background-color: #e9e8ff;
    }

    .blogDetails {
        max-width: 1420px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 40px;

        h1 {
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            text-align: center;
            margin-bottom: 50px;
            color: #282461;
        }

        h2 {
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            text-align: left;
            margin-bottom: 25px;
        }

        h3 {
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            text-align: left;
            margin-bottom: 25px;
            margin-top: 25px;
        }

        p {
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            text-align: justify;
            letter-spacing: -.4px;
        }

        ol { 

            li {
                font-family: "Noto Sans", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                text-align: justify;
                letter-spacing: -.4px;
                margin-bottom: 5px;
            }
        }
    }
}