.pdrPage {
    box-sizing: border-box;
    background-color: #FFFCF7;

    * {
        box-sizing: border-box;
    }

    .containerBox {
        max-width: 1420px;
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;

    }

    .containerFluidBox {
        width: 100%;
        padding: 0 40px;
        margin: 0 auto;
    }
}

.letsTalkPopup {
    max-width: 700px;
    margin: 0 auto;

    .contactUs {
        padding: 30px 0 30px;
        min-height: auto;
        border-radius: 10px;
        max-height: 90vh;
        overflow-y: auto;
        scrollbar-width: thin;

        .textAreaGroup .textAreaField textarea {
            height: 70px !important;
        }

        .inputGroup .inputField input,
        .inputGroup .inputField select {
            padding: 0 20px;

        }
    }
}

@media only screen and (max-width: 1279px) {
    .letsTalkPopup {
        max-width: 700px;
        margin: 0 auto;

        .contactUsContainer {
            padding: 0 20px !important;

            .pageTitle {
                margin-bottom: 30px;

                h2 {
                    font-size: 30px;
                }

                p {
                    font-size: 20px;
                }
            }

            .inputGroup .inputField .MuiInputBase-root input {
                height: 50px !important;
                padding: 0 15px !important;
            }

            .formArea .inputFieldBox .inputGroup .inputLabel {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: normal;
                color: #000000;
                margin: 0px 0 10px;
            }

            .formArea .inputFieldBox {
                width: calc(100% - 0px);
                margin-bottom: 25px;
            }

            .primaryBtn {
                margin-top: 35px;

                button {
                    width: 200px;
                    height: 55px;
                    font-size: 17px;
                }
            }
        }
    }

    .pdrPage {
        .header {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

            img {
                width: 100% !important;
                max-width: 140px;
            }

            .primaryBtn {
                height: 50px;

                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.64px;
                }

                img {
                    width: 18px !important;
                }
            }
        }

        .containerBox {
            padding: 0 25px;
        }

        .containerFluidBox {
            padding: 0 25px;
        }

        .pdrSection {
            padding-bottom: 0;

            .pdrBanner {
                display: block;

                .bannerContent {
                    width: 100%;

                    h2 {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 49px;
                        /* 153.125% */
                        letter-spacing: -0.96px;

                        span {
                            line-height: 49px;
                        }
                    }

                    p {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;
                        /* 175% */
                        letter-spacing: -0.64px;
                    }

                    a {
                        height: 50px;
                        margin-top: 35px;
                        width: 160px;

                        p {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }
                }

                .inBannerImg {
                    width: 100%;
                }
            }
        }

        .whyIngeniaPdr {
            padding-bottom: 50px;

            .rowBox {
                display: block;

                .whyBox {
                    width: 100%;
                    margin-bottom: 16px;

                    h2 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 121.7%;
                        /* 24.34px */
                        letter-spacing: -0.8px;
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    .imgBox {
                        width: 60px;
                        height: 60px;

                        img {
                            padding: 12px;
                        }
                    }
                }
            }
        }

        .inventorySegmentation {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                img {
                    width: 100%;
                }
            }
        }

        .howItsWorksPdr {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                .howItsWorkBox {
                    width: 100%;
                    margin-bottom: 15px;

                    &:last-child {
                        margin: 0;
                    }

                    h2 {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        /* 162.5% */
                    }
                }
            }
        }

        .movementScore {
            padding: 0 0 50px;

            .rowBox {
                display: grid;
                gap: 0;

                .movementScoreContent {
                    width: 100%;
                    order: 1;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    .tagBox {
                        margin-bottom: 25px;
                        span {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 121.7%;
                            /* 19.472px */
                            letter-spacing: -0.64px;
                            padding: 10px 25px;
                        }
                    }
                }

                .movementScoreImage {
                    width: 100%;
                    order: 2;
                }
            }
        }

        .aIDiscountRecommendation {
            padding: 0 0 50px;

            .rowBox {
                display: block;

                .aIDiscountRecommendationContent {
                    width: 100%;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }

                    .listBox {
                        margin-bottom: 25px;

                        li {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }
                }

                .aIDiscountRecommendationImage {
                    width: 100%;
                }
            }
        }

        .optimumBusiness {
            padding: 50px 0 30px;

            .rowBox {
                display: block;

                h2 {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 39px;
                    /* 121.875% */
                    letter-spacing: -1.28px;
                    text-align: center;
                }

                p {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.8px;
                }
            }
        }

        .inventoryForecasting {
            padding: 50px 0 30px;

            .rowBox {
                display: block;

                .inventoryForecastingContent {
                    width: 100%;
                    margin-top: 30px;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }
                }

                .inventoryForecastingImage {
                    width: 100%;
                }
            }
        }

        .storeWiseRecommendations {
            padding: 0 0 100px;

            .rowBox {
                display: block;
                margin-top: 25px;
            }
        } 


        .aboutIngeniaPdr {
            padding: 0 0 0;

            .rowBox {
                display: block;
                text-align: center;
                padding: 40px 15px;
                margin: 0;

                h2 {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 121.7%;
                    /* 38.944px */
                    letter-spacing: -1.28px;
                    max-width: 550px;
                    margin: 0 auto;
                }

                a {
                    margin: 35px auto 0;
                    width: 140px;
                    height: 50px;

                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.64px;
                    }
                }
            }
        }

        .footerBox {
            background-color: #FFFCF7;
            padding: 50px 0 55px;

            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.64px;
            }
        }
    }
}

@media screen and (max-width: 1279px) and (min-width: 768px) {
    .pdrPage {
        .pdrSection {
            padding-bottom: 0;

            .pdrBanner {
                display: flex;

                .bannerContent {
                    width: calc(50% - 15px);

                }

                .inBannerImg {
                    width: 50%;
                }
            }
        }

        .whyIngeniaPdr {
            padding: 50px 0px 35px;

            .rowBox {
                display: flex;
                margin-bottom: 15px;

            }
        } 
        .inventorySegmentation {
            padding: 35px 0;

            .rowBox {
                display: flex;

                img {
                    width: 100%;
                }
            }
        }

        .howItsWorksPdr {
            padding: 35px 0;

            .rowBox {
                display: flex;
                gap: 10px;
                .howItsWorkBox {
                    width: calc(33.33% - 5px);
                    margin-bottom: 0;

                    &:last-child {
                        margin: 0;
                    }  
                }
            }
        }

        .movementScore {
            padding: 35px 0;

            .rowBox {
                display: flex;
                gap: 30px;

                .movementScoreContent {
                    width: calc(49% - 30px);
                    order: unset; 

                    .tagBox {
                        margin-bottom: 0;
                        span { 
                            padding: 10px 25px;
                        }
                    }
                }

                .movementScoreImage {
                    width: 51%;
                    order: unset;
                }
            }
        }

        .aIDiscountRecommendation {
            padding:35px 0;

            .rowBox {
                display: flex;
                gap: 30px;
                .aIDiscountRecommendationContent {
                    width: calc(50% - 30px); 
                }

                .aIDiscountRecommendationImage {
                    width: 50%;
                }
            }
        }

        .optimumBusiness {
            padding:35px 0;

            .rowBox {
                display: flex;  
            }
        }

        .inventoryForecasting {
            padding: 70px 0 35px;

            .rowBox {
                display: flex;
                gap: 30px;

                .inventoryForecastingContent {
                    width: calc(50% - 30px);
                    margin-top: 0; 
                }

                .inventoryForecastingImage {
                    width: 50%;
                }
            }
        }

        .storeWiseRecommendations {
            padding: 35px 0 70px; 
        }  
        .aboutIngeniaPdr {
            padding: 0 0 0;

            .rowBox {
                display: flex;
                text-align: left;
                padding: 30px;
                margin: 0;
                .sectionHeading {
                    margin: 0 !important;
                }
                h2 { 
                    margin: 0 0;
                    text-align: left;
                }

                a {
                    margin: 0 auto 0;  
                }
            }
        }

        .footerBox {
            background-color: #FFFCF7;
            padding: 50px 0 55px; 
        }
    }
}