.cepSection {
    background-color: #FFFCF7;
    padding: 25px 0;

    .cepBanner {
        display: flex;
        gap: 15px;
        align-items: center;

        .bannerContent {
            width: calc(50% - 15px);

            h2 {
                color: #282461;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 69px;
                letter-spacing: -1.5px;
                text-transform: capitalize;
                margin: 0 0 15px;

                span {
                    font-weight: 700;
                    display: block;
                    line-height: 60px;
                }
            }

            p {
                color: #000;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                /* 127.273% */
                letter-spacing: -0.88px;
                margin-bottom: 10px;
            }

            .primaryBtn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 2px 2px 2px 2px;
                gap: 10px;
                width: 208px;
                height: 69px;
                cursor: pointer;
                background: #282461;
                border-radius: 10px;
                transition: all 0.3s ease-out;
                margin: 60px 0 0;

                &:hover {
                    background-color: #6864a0;
                    text-decoration: none;
                }

                p {
                    font-family: "Noto Sans", sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 33px;
                    letter-spacing: -0.04em;
                    color: #ffffff;
                    margin: 0;
                }
            }
        }

        .inBannerImg {
            width: 50%;
            background: url(../../../statics/svgs/bannerBg.svg) no-repeat center right / contain;
            padding: 70px 0;
            img {
                width: 100%;
                box-shadow: 0 0  100px rgba(0, 0, 0, 0.10);
                background: #f6f3f3;
            }
        }
    }
}