.inputGroup {
    .inputField {
        width: 100%;

        .MuiInputBase-root {
            &::before {
                display: none;
            }

            &::after {
                display: none;
            }

            input {
                font-weight: 400;
                font-size: 16px;
                height: 7vh;
                color: #000;
                padding: 0 36px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 10px;

                &:focus {
                    border: 1px solid rgba(0, 0, 0, 0.9);
                }

            }
        }
  
    }

    .inputLabel {
        font-style: normal;
        font-weight: 600;
        font-size: 2.2vh;
        line-height: normal;
        color: #000000;
        margin: 0 0 1.8vh;
    }
}