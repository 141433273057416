.storeWiseRecommendations {  
    position: relative; 
    z-index: 0; 
    padding-top: 60px;
    padding-bottom: 130px;

    h2 {
        text-align: center;
    }
    .sectionHeading p {
        max-width: 1250px;
    }
    .rowBox { 
        border-radius: 10px; 
        box-shadow: 1.71px 1.14px 8.067px 0px rgba(0, 0, 0, 0.01), 7.525px 5.017px 16.704px 0px rgba(0, 0, 0, 0.02), 18.472px 12.314px 33.313px 0px rgba(0, 0, 0, 0.03), 35.575px 23.717px 65.296px 0px rgba(0, 0, 0, 0.04), 59.861px 39.908px 120.058px 0px rgba(0, 0, 0, 0.05), 92.358px 61.572px 205px 0px rgba(0, 0, 0, 0.06); 
        img {
            width: 100%;
        }
    }
}