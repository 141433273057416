.statisticsData {
    background-color: #FFFCF7;
    padding: 65px 0 60px;

    .rowBox {
        display: flex;
        background: url(../../../statics/svgs/StatisticsDataBg.svg) no-repeat center center / contain;
        width: 100%;

        .statisticsDataBox {
            width: calc(33.3333% - 0px);
            display: flex;
            align-items: center;
            // background: url(../../../statics/svgs/StatisticsDataBg1.svg) no-repeat center center / contain;
            min-height: 500px;
 

            .centerBox {
                max-width: 340px;
                margin: 0 auto;

                h2 {
                    color: rgba(0, 0, 0, 0.90);
                    text-align: center;
                    font-size: 60px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 121.7%;
                    /* 73.02px */
                    letter-spacing: -2.4px;
                    margin-bottom: 12px;
                }

                p {
                    color: rgba(0, 0, 0, 0.80);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.8px;
                    margin-bottom: 40px;

                }

                h3 {
                    color: #282461;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.64px;

                    span {
                        font-weight: 600;
                    }
                }

            }

        }
    }
}