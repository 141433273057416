.propositionSection {
  box-sizing: border-box;
  background-color: #FFFCF7;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh);
  padding-top: 12vh;
  padding-bottom: 15vh;
  display: flex; 

  .propositionGrid {
    display: flex;
    justify-content: space-between;

  }

  .bannerTitle { 
    font-style: normal;
    line-height: 121.7%;
    font-weight: 700;
    letter-spacing: -0.03em;
    color: #282461;
    width: 100%;
    margin: auto;
    font-size: 5.3vh !important;
    max-width: 1360px;
    margin: 0 auto;
  }
  .proposSubTitle { 
      font-style: normal;
      line-height: 121.7%;
      font-weight: 700;
      letter-spacing: -0.03em;
      color: #282461;
      width: 100%; 
      font-size: 4vh !important;
      max-width: 1000px;
      margin: 25px auto 0; 
      text-align: center;
  }
  .propositionBanner {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .propositionBanner img {
    // height:0px;
    width: 100%;
    height: 15.5vw;
  }

  & * {
    box-sizing: border-box;
  }

}

@media screen and (max-width:767px) {
  .headerContainer  {
    padding: 15px 20px !important;
  }
  .propositionSection {
    padding:95px 20px 0 !important; 
    .propositionSectionContainer {
      padding: 0;
    }
    .bannerTitle { 
      margin: auto;
      font-size:32px !important;  
      text-align: left;
      line-height: normal;
    }
    .propositionBanner{
      padding-top: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .propositionGrid {
      display: block; 
      .propositionSectionGrid {
        width: 100% !important;
        margin-bottom: 60px;
        div {
          min-height: auto !important;
          .subHead { 
            font-size: 32px ; 
            margin-bottom: 10px;
          }
          .subPrea {
            font-size: 20px ; 
          }
        }
      }
    } 
  }
}