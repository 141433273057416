.valueGridBox{
position: relative; 
.subHeading{
font-family: "Noto Sans", sans-serif;
font-style: normal;
font-weight: 300;
font-size: 4vh;
padding-bottom: 12px;
letter-spacing: -0.04em;
color: rgba(0, 0, 0, 0.9) !important;
}
.subPrea{
font-family: "Noto Sans", sans-serif;
font-style: normal;
font-weight: 400;
font-size: 2vh;
line-height: 2.8vh;
letter-spacing: -0.04em;
color: rgba(0, 0, 0, 0.8);
min-height: 5.6vh;
}
.valueImg{
  position: absolute; top:0;
  width: 13%;
 }
}