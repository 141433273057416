.whatWeHaveDone {
    background-color: #FFFCF7;
    padding: 60px 0 60px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 100px;

        .whatWeHaveDoneBox {
            width: calc(33.33% - 50px);

            .iconBox {
                width: 111px;
                height: 111px;
                border-radius: 50%;
                background-color: rgba(40, 36, 97, 1);
                border: 10px solid #fff;
                margin: 0 auto 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 8px solid rgba(22, 18, 71, 1);
            }

            h2 {
                color: #282461;
                text-align: center;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -1.6px;
                text-transform: capitalize;
            }

            p {
                color: #000;
                text-align: center;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -1.36px;
                text-transform: capitalize;
                margin: 0;
            }
        }
    }
}