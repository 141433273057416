.valueGridBox {
    border-radius: 15px;
    border: 1px solid #D6D6D6;
    background: #FCF9F4;
    padding: 35px 15px;
    text-align: center;

    h3 {
        color: rgba(0, 0, 0, 0.90);
        text-align: center;
        font-size: 3.2vh;
        font-style: normal;
        font-weight: 300;
        line-height: 121.7%;
        /* 43.812px */
        letter-spacing: -1.44px;
        margin-bottom: 15px;
        min-height: 7.9vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    p {
        color: rgba(0, 0, 0, 0.80);
        text-align: center;
        font-size: 1.8vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.8px;
    }
    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #282461;
        padding: 25px;
        margin-bottom: 10px;
    }
}