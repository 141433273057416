.aIDiscountRecommendation {
    background-color: #FFFCF7;
    padding: 65px 0 130px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 80px;
        align-items: center;

        .aIDiscountRecommendationContent {
            width: calc(50% - 80px);
            text-align: left;

            h2 {
                font-weight: 600;
                text-align: left;
            }

            p {
                color: rgba(0, 0, 0, 0.80);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.8px;
                margin-bottom: 22px;
            }



            .listBox {
                margin: 0; 
                padding-left: 18px;  

                li {
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.8px;
                    margin: 0;
                    strong {
                        font-weight: 600;
                    }
                }
            }
        }

        .aIDiscountRecommendationImage {
            width: 50%;

            img {
                width: 100%;
                box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.03);
            }
        }
    }
}