.optimumBusiness {
    background: #E8E8E8 url(../../../statics/svgs/optimumBusinessBg.svg) no-repeat center center / contain;
    padding: 120px 0;

    .sectionHeading {
        h2 {
            color: #282461;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.6px;
        }

        p {
            color: rgba(0, 0, 0, 0.80); 
            text-align: center; 
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.8px;
            max-width: 1250px;
        }
    }
}