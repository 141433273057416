.sectionHeading {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    h2 {
        color: #282461;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.32px;
        text-transform: capitalize;
    }

    p {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.8px;
        max-width: 700px;
        margin: 0 auto;
        text-align: center;
    }
}

@media only screen and (max-width: 1279px) {
    .sectionHeading {
        margin-bottom: 20px !important;
        h2 {
            color: #282461;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.96px;
            text-transform: capitalize;
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            /* 175% */
            letter-spacing: -0.64px;
        }
    }
}