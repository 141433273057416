.movementScore {
    background-color: #FFFCF7;
    padding: 50px 0 65px;

    .rowBox {
        display: flex;
        justify-content: space-between;
        gap: 60px;
        align-items: center;

        .movementScoreContent {
            width: calc(49% - 60px);
            text-align: left;

            h2 {
                font-weight: 600;
                text-align: left;
            }

            p {
                color: rgba(0, 0, 0, 0.80);
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.8px;
                margin-bottom: 22px;
            }

            h3 {
                color: rgba(0, 0, 0, 0.90);
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: 121.7%;
                /* 36.51px */
                letter-spacing: -1.2px;
                margin-bottom: 22px;
            }

            .tagBox {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;
                span {
                    border-radius: 50px;
                    border: 1px solid #000;
                    background: #E8E8E8;
                    color: #000; 
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 121.7%; /* 24.34px */
                    letter-spacing: -0.8px;
                    padding: 10px 40px;
                }
            }
        }

        .movementScoreImage {
            width: 51%;

            img {
                width: 100%;
                box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.03);
            }
        }
    }
}